.spaces-list__list {
    @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4
}

.spaces-list__list:has(.spaces-list__no-results) {
    display: flex; /* Change to flex display when the child exists */
}

.spaces-list__item > a {
    @apply flex flex-col rounded-[15px] overflow-hidden
}
.spaces-list__item > a   .spaces-list__item_content {
    @apply py-4 px-4
}

.spaces-list__item > a {
    @apply   border-[1px] border-gray-100
}
.spaces-list__item > a   .spaces-list__item_content .content{
     @apply text-sm mt-2
}
.spaces-list__item > a   .spaces-list__item_content .title{
     @apply text-base
}

.cat-content .code.content {
    @apply max-w-full text-blue-50
}

.cat-content .code.content p {
    @apply max-w-full text-blue-50 mb-5 mt-3
}
.cat-content .code.content h3 {
    @apply text-lg text-blue-100 mt-2 mb-2 font-montserrat
}

.cat-content .code.content ul {
    @apply  list-disc list-inside space-y-2 pl-4 text-blue-50
}

.cat-content .code.content ul strong {
    @apply text-blue-100
}


.space-category__image {
    @apply min-h-full flex-1 object-cover object-center;

}

.spaces-category__item {
    @apply
    flex-1  flex flex-col
    border-2
    border-transparent
    hover:border-blaze-500
    hover:elevation-1
    rounded-[10px]
    overflow-hidden
    cursor-pointer
    relative
        h-full
    min-w-[292px];
    box-sizing: border-box;
    transition: transform 0.3s ease;
    user-select: none; /* Prevent text selection */
    -webkit-user-select: none; /* Prevent text selection for Webkit browsers */
    -moz-user-select: none; /* Prevent text selection for Firefox */
    -ms-user-select: none; /* Prevent text selection for IE/Edge */
    touch-action: pan-y; /* Prevent touch scrolling during drag */
}


.spaces-category__item.selected {
    @apply  border-blaze-500
    elevation-1
}
.spaces-category__item_title {
    @apply  mt-2 absolute bottom-0 text-center py-1 w-full text-sm left-0 text-white font-bold
}
.spaces__filter-title {
    @apply mt-[40px] mb-[20px] font-bold font-montserrat text-blaze-500
}

.spaces-category__item:before {
    position: absolute;
    top:0;
    left:0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(1.88, 9.39, 56.31, 0.50) 10%, rgba(169.76, 180.20, 245.44, 0) 35%);
}

.spaces-categories {
    @apply whitespace-nowrap overflow-hidden flex relative
}

.carousel-wrapper {
    @apply  w-full flex relative
}

.carousel {
    display: flex;
    transition: transform 0.3s ease-out;
    will-change: transform;
    align-items: center; /* Ensure items are centered vertically */
    gap:10px;
}


.spaces-categories:hover .carousel-button {
    background: rgba(0, 0, 0, 0.5);
}


.spaces-categories .carousel-button:hover {
    background: rgba(0, 0, 0, 0.7);
}
.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 5;

}

.carousel-button--prev {
    left: 10px;
}

.carousel-button--next {
    right: 10px;
}