footer#footer {
    @apply
    transition-all
    bg-blue-950
    text-white
    min-w-full
    prose
    prose-a:text-white
    hover:prose-a:text-blaze-300
    prose-p:font-rubik
    prose-p:text-white
    prose-h4:text-blue-100
    prose-h4:font-montserrat
    prose-h4:my-0
    prose-ul:list-none
    prose-ul:pl-0
    prose-ul:flex-1
    prose-ul:flex
    prose-ul:flex-col
    prose-ul:w-full
    prose-ul:gap-2
    prose-li:pl-0
    prose-li:my-0
    prose-a:no-underline
    md:py-[100px]
    py-[50px]
}

footer#footer  .row:first-child {
    @apply lg:!gap-[65px]
}

footer#footer .sitemap {
    @apply lg:!gap-[30px] gap-[15px] flex md:flex-row flex-1 py-[10px] lg:py-[50px] flex-wrap lg:flex-nowrap
}

footer#footer .business {
    @apply column flex-1 lg:max-w-[320px]
}

footer#footer .business > img {
    @apply  max-w-[250px]
}


footer#footer .sitemap > .column {
    @apply !gap-2  min-w-[47%] lg:min-w-0
}

footer#footer .business {
    @apply column py-[10px] lg:py-[50px]
}
