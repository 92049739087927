#cc-main {
    /** Change font **/
    --cc-font-family: 'Montserrat', sans-serif;

    /** Change button primary color to black **/
    --cc-btn-primary-bg: #f26716;
    --cc-btn-primary-border-color: #f26716;
    --cc-btn-primary-hover-bg: #e45112;
    --cc-btn-primary-hover-border-color: #e45112;

    /** Also make toggles the same color as the button **/
    --cc-toggle-on-bg: var(--cc-btn-primary-bg);

    /** Make the buttons a bit rounder **/
    --cc-btn-border-radius: 10px;
}
