header#header {
    @apply py-5 elevation-1 bg-white z-10
}


header#header {

    position: sticky;
    top: 0;
    transition: transform 150ms ease-in-out;
}

header#header .row:first-child {
    @apply items-center gap-[10px] lg:gap-[20px] xl:gap-[60px] flex-1 flex-row
}

header#header .row:first-child > a {
    @apply content-center
}

header#header .main-menu {
    @apply md:flex flex-row flex-1 hidden
}
header#header .main-menu  ul {
    @apply flex flex-row gap-[10px] lg:gap-[20px] xl:gap-[30px]
}
header#header .main-menu  ul  li {
    @apply content-center
}
header#header ul > li > a, header#header ul > li > span{
    @apply text-nowrap text-blue-950 text-sm lg:text-base font-montserrat hover:text-blaze-600 cursor-pointer flex flex-row gap-1 lg:gap-2 items-center
}
header#header .secondary-menu {
    @apply md:flex flex-row gap-[10px] lg:gap-[20px] items-center hidden;
}

header#header  .header-btn {
    @apply max-w-[150px] self-end
}

header#header .burger {
    @apply flex md:hidden items-center mt-2 text-gray-500 hover:text-gray-600 cursor-pointer
}

header#header .logo {
    @apply min-w-[200px]
}

.mega-menu__column {
    @apply flex flex-col gap-[10px]
}

.mega-menu__column_container {
    @apply grid grid-cols-6 gap-3 lg:gap-4 py-4
}

.mega-menu__column_container.solutions {
    @apply grid grid-cols-4 gap-4 py-4
}


.mega-menu__column .title {
    @apply border-b-[1px] border-gray-200 pb-2 text-sm lg:text-base xl:text-lg
}

.mega-menu__column a {
    @apply p-2 hover:bg-gray-50 rounded-md flex-1
}
.mega-menu__column h3 {
    @apply px-2
}
.mega-menu__column .link {
    @apply text-blue-950 font-lora text-base
}

.mega-menu_dropdown_container {
    @apply absolute left-0 top-full w-full bg-white shadow-lg z-10
}

.mega-menu__column p {
    @apply text-gray-500 text-sm
}

.mega-menu__column a:hover > p {
    @apply text-gray-700
}

/* Sidebar menu styles */
header#header .sidebar-menu {
    @apply px-4  pt-12 fixed md:hidden top-0 left-0 h-screen w-full max-w-xs bg-white z-50 transform transition-transform ease-in-out duration-300;
    transform: translateX(-100%); /* Off-screen initially */
}

/* When menu is visible */
header#header .sidebar-menu.visible {
    transform: translateX(0); /* Slide in */
}

header#header .sidebar-menu.visible .main-menu , header#header .sidebar-menu.visible .secondary-menu{
    @apply flex flex-col w-full items-start mt-3
}
header#header .sidebar-menu.visible .main-menu > ul {
    @apply flex flex-col
}


header#header .sidebar-menu.visible .main-menu > ul .mega-menu_dropdown_container {
    @apply relative shadow-none top-auto
}

header#header .sidebar-menu.visible .main-menu > ul .mega-menu_dropdown_container .mega-menu__column_container {
    @apply flex flex-col
}

/* Overlay to cover the rest of the screen */
.menu-overlay {
    @apply fixed inset-0 bg-black opacity-50 z-40;
}

/* Close button styling */
.menu-close {
    @apply absolute top-5 right-5 text-gray-500 cursor-pointer;
}

/* Rest of your existing CSS for menus */
.secondary-menu {
    @apply md:flex flex-row gap-[10px] lg:gap-[20px] items-center hidden;
}

header#header .sidebar-menu.visible .mega-menu__column .title,
header#header .sidebar-menu.visible .mega-menu__column a {
    @apply px-0
}

/* Additional styling as needed */

.mega-menu__column a.no-hover {
    @apply p-0 hover:bg-transparent
}