.facilities {
    @apply flex flex-col gap-2
}

.facility {

}
.facility__grid {
    @apply grid grid-cols-2 lg:grid-cols-3 gap-2
}
.facilities__item {
    @apply flex flex-col p-4 rounded-[15px] border-[1px]  border-gray-200
 }

.facilities__item__title {
    @apply text-base text-gray-800 font-medium  font-montserrat flex flex-row gap-2 items-center
}

.facilities__header_title, .full_gallery__title {
    @apply text-blaze-500 font-bold mb-0 text-2xl mb-4
}


.facilities__item__desc {
    @apply text-sm mt-3
}

.facility__header_title {
    @apply mb-4 font-montserrat text-blue-950 text-lg;
}

.space section {
    @apply py-[10px]
}
.timetable li {
    @apply flex flex-row gap-2 text-gray-500 text-sm
}

.timetable li > span {
    @apply flex-1 max-w-[100px] text-gray-600 font-bold font-montserrat
}

.slider-container {
    position: relative;
    width: 80%; /* Ensure this has a defined width */
    height: 700px; /* Set a specific height or adjust as needed */
    overflow: hidden; /* Hide overflow to prevent scrolling issues */
    margin: 0 auto;
}

/* Slider which holds all slides */
.slider {
    display: flex; /* Align slides horizontally */
    width: 100%;
    height: 100%;
    position: relative; /* Position context for absolutely positioned slides */
    transition: transform 0.5s ease; /* Smooth transition for sliding effect */
}
/* Individual slide */
.slide {
    flex: 0 0 100%; /* Each slide takes up 100% of the slider's width */
    height: 100%; /* Each slide takes up 100% of the container's height */
    box-sizing: border-box; /* Include padding and borders in the element's total width and height */
    position: absolute; /* Ensure content inside the slide is positioned correctly */
    display: flex;
    width: 100%;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    color: white;
}

.slider-button.prev {
    left: 10px;
}

.slider-button.next {
    right: 10px;
}

.slide img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
    border-radius: 15px;
}

.full-gallery img {
    @apply cursor-pointer
}
.full-gallery > div >div{
    @apply relative
}
.full-gallery > div >div:hover:after {
    -webkit-mask: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%221.5%22%20stroke%3D%22currentColor%22%20class%3D%22size-6%22%3E%3Cpath%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20d%3D%22m21%2021-5.197-5.197m0%200A7.5%207.5%200%201%200%205.196%205.196a7.5%207.5%200%200%200%2010.607%2010.607ZM10.5%207.5v6m3-3h-6%22%20/%3E%3C/svg%3E");
    mask: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%221.5%22%20stroke%3D%22currentColor%22%20class%3D%22size-6%22%3E%3Cpath%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20d%3D%22m21%2021-5.197-5.197m0%200A7.5%207.5%200%201%200%205.196%205.196a7.5%207.5%200%200%200%2010.607%2010.607ZM10.5%207.5v6m3-3h-6%22%20/%3E%3C/svg%3E");
    -webkit-mask-size: cover;
    mask-size: cover;
    content:'';
    width: 30px;
    height: 30px;
    display: block;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


}

.full-gallery > div >div:hover > img {
    filter: brightness(0.6);
}

.facility__header {
    margin-bottom: 20px;
}


.solutions {
    @apply px-4 mt-[35px]
}
.solutions .solution {
    @apply p-2   mb-6 flex flex-col
}
.solutions .solution > .solution__title {
    @apply text-blaze-500 font-bold font-montserrat text-lg
}

.solutions .solution > .solution__desc {
    @apply font-normal font-montserrat text-sm text-gray-500 mb-4
}

.solutions .solution .solution__option {
    @apply mt-2 mb-2 border-[1px] border-gray-200 p-4 rounded-[15px]
}

.solutions .solution .solution__option .solution__option__desc {
    @apply font-normal font-montserrat text-sm text-gray-400
}

.solutions .solution .solution__option  .solution__option__title {
    @apply font-lora text-oblique text-blue-950 text-lg
}

.solution__option__buy {
    @apply mt-3 mb-3  text-sm text-gray-500
}

.solution__option__buy  > span {
    @apply text-lg text-blaze-500 font-bold
}