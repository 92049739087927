.white, .none {
    @apply bg-white
}

.primary {
    @apply bg-blaze-50
}

.secondary {
    @apply bg-blue-50
}

.secondary-dark {
    @apply bg-blue-950
}

.primary-dark {
    @apply bg-blaze-950
}



.banner {
    background-image: url( '../../../public/images/_bg_dot_effect.svg' );
    @apply py-[70px]
}

.banner > .container {
    @apply gap-[20px] md:gap-[100px]
}

.banner.banner-content .column:last-child {
    @apply pl-5
}
.banner.banner-content .container.row {
    @apply md:gap-[50px]
}
.banner.banner-content .column:last-child  > .additional {
    @apply p-4 border-[1px] elevation-2 rounded-[10px] bg-white min-h-[200px] flex flex-col justify-center items-center
}

.banner .column:first-child {
    @apply gap-[30px] justify-center
}
.banner .content > p {
    @apply text-xl font-light text-gray-500
}

.banner .content .icon > p {
    @apply text-xl font-light text-gray-500
}

.banner .title-container .strapline {
    @apply text-2xl mb-2
}

.banner .title-container .title {
    @apply text-xl md:text-2xl lg:text-3xl 2xl:text-5xl
}

.banner .additional {
    @apply flex gap-4 flex-col
}

/**
Code
 */
.code.content {
    @apply prose

    prose-blockquote:text-base
    prose-blockquote:text-gray-500
    prose-h2:text-xl prose-h2:text-blue-950 prose-h2:mt-2
    prose-h3:text-lg prose-h3:text-blue-900
    prose-p:text-base prose-p:text-gray-500
    prose-li:text-base prose-li:text-gray-500
    prose-a:text-sm prose-a:text-blaze-500 prose-a:hover:text-blaze-600
}
/***
    Buttons start
 */

a[class*="btn-"], button[class*="btn-"], div[class*="btn-"] {
    @apply py-2 px-4 rounded-[3px] font-montserrat text-base  border-[1px] self-start leading-tight    transition-all
}
a[class*="btn-"] > span {
    @apply text-base font-montserrat justify-center items-center
}

.btn-primary {
    @apply
    bg-orange-500
    border-transparent
    text-white
    hover:bg-blaze-600
    focus:bg-blaze-600
    group-hover:text-blaze-100
    disabled:bg-blaze-200
    disabled:text-blaze-900
    disabled:hover:text-white
    disabled:hover:bg-blaze-200
}

.btn-secondary {
    @apply
    bg-blue-950
    border-transparent
    text-white
    hover:bg-blue-900
    focus:bg-blue-900
    group-hover:text-orange-100
    disabled:bg-blaze-200
    disabled:text-blaze-900
}


.btn-primary-knock {
    @apply rounded-[3px]
    bg-white
    border-blaze-500
    text-blaze-600
    hover:bg-blaze-50
    focus:bg-blaze-50
    group-hover:text-orange-100
    disabled:bg-blaze-50
    disabled:text-blaze-900
}

.btn-secondary-knock {
    @apply rounded-[3px]
    bg-white
    border-blue-950
    text-blue-950
    hover:bg-blue-50
    focus:bg-blue-50
    group-hover:text-blue-950
    disabled:bg-blue-50
    disabled:text-blue-950
}

.btn-simple {
    @apply !px-0 !py-0 !border-0 text-blaze-500 hover:text-blaze-600
}



.btn-primary span {
    @apply text-white hover:text-blaze-100
}

.btn-primary:disabled span {
    @apply text-white hover:text-white
}

.two-column-image-text > .container {
    @apply lg:flex-row flex-col gap-[50px]
}



.two-column-image-text > .container > .column figure {
    width: 100%;
    max-height: 100%;
}

.two-column-image-text img {
    @apply w-full rounded-[10px]
}

.two-column-image-text.image-end > .container.row {
    @apply lg:flex-row-reverse

}



.image-cta {
    @apply relative
}

.image-cta > img {
    @apply w-full object-cover rounded-[30px]
}

.image-cta > .overlay {
    @apply absolute top-0 left-0 w-full h-full p-[50px] flex rounded-[30px];
    background: linear-gradient(154deg, rgba(1.88, 9.39, 56.31, 0.60) 20%, rgba(169.76, 180.20, 245.44, 0) 45%);
    background: rgba(0, 0, 0, 0.6);
}

.image-cta > .overlay > a {
    @apply flex flex-col h-full flex-1 content-stretch
}

.image-cta > .overlay .title-container {
    @apply flex-1
}

.image-cta .btn-primary {
    @apply group-hover:bg-blaze-600 self-end
}

.image-cta .cta-title {
    @apply text-blaze-50 text-3xl font-montserrat font-black;

}
.image-cta .cta-content {
    @apply mt-4
}
.image-cta .cta-content p {
    @apply text-white text-base lg:text-xl w-full xl:max-w-[50%]
}

/**
Icon Start
 */
.icon-group  .icon-group__icons {
    @apply flex flex-col md:flex-row justify-between gap-[50px] mt-6
}

.icon-group {
    @apply flex flex-col gap-[20px]
}

.icon-group.secondary {
    @apply bg-blue-50
}


.icon-group.secondary  .icon.border {
    @apply border-blue-200
}
.icon {
    @apply flex flex-col gap-3 flex-1
}

.icon.border {
    @apply  p-4 border-[1px] border-gray-100 rounded-[5px]
}

.icon.has-background {
    @apply bg-blaze-50
}

.icon .icon-container {
    @apply flex flex-row content-center gap-3 items-center
}


.icon .icon-title {
    @apply text-gray-700 font-rubik font-medium flex-1
}

.icon .left-title svg {
    @apply size-2
}


.icon .icon-container.left-title .icon {
    @apply w-5 h-5
}


.secondary-dark .icon .content p{
    @apply text-sm text-blue-200
}

.secondary-dark .icon-title {
    @apply text-blue-100
}

.icon .content {
    @apply text-base text-gray-500
}

.icon .icon-container.above-title {
    @apply flex flex-col gap-[5px] items-start flex-none
}

.icon .icon-container.above-title .icon-title {
    @apply flex-none
}
.icon .icon-container .icon {
    @apply size-8 flex-none
}
.icon .icon-container.above-title .icon {
    @apply w-12 h-12 mb-4
}
.icon.bg-block {
    @apply bg-blaze-50
}

.icon.bg-block .icon-container > svg {
    @apply fill-blaze-500
}



.icon.bg-icon .icon-container > svg {
    @apply fill-white bg-blaze-500 rounded-[5px] p-2 text-white
}

/**
Faqs Start
 */
.faqs > .container {
    @apply flex flex-col gap-[20px]
}

.faqs > .container > div {
    @apply flex-1
}

.faqs:not(.content-above) > .container > div:first-child {
    @apply max-w-[30%]
}
.faqs.content-right > .container {
    @apply flex flex-col lg:flex-row-reverse
}

.faqs.content-left > .container {
    @apply flex flex-col lg:flex-row
}

.faqs .faqs__content {
    @apply gap-[20px] flex flex-col
}

.faqs .faqs__faq.grid-style {
    @apply grid grid-cols-2 gap-[20px]
}

.faqs  .faqs__faq.list-style {
    @apply flex flex-col gap-[20px]
}

.faqs  .faq {
    @apply border-b-[1px] border-gray-200 pb-4
}


.faq__title {
    @apply text-blue-950 mb-2 font-montserrat font-bold
}
.faq__content > p {
    @apply text-xs text-gray-500
}

.register {
    @apply bg-gray-100 rounded-[20px] max-w-[600px] w-full mx-auto p-[30px]
}

.register form .title {
    @apply mb-3
}

.register form > p {
    @apply font-montserrat mb-4 text-gray-500
}

.code.content.timeline-content :is(:where(p):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
    @apply !text-left font-montserrat !text-lg text-gray-500
}

.home-cta .image-cta .cta-title{
    @apply md:text-xl xl:text-4xl 2xl:text-5xl  lg:h-[100px] mb-2 font-montserrat;

}

.activities {
    @apply py-[50px] md:py-[100px] items-center max-h-[900px]
}

.activities .title {
    @apply text-6xl  md:text-3xl  xl:text-6xl text-blue-50 max-w-full lg:max-w-[70%]  leading-tight
}

.activities .under-strapline {
    @apply text-gray-100 text-xl xl:text-3xl mb-[100px]
}

.acivity__items {
    @apply flex flex-row flex-wrap gap-[15px] xl:gap-[30px]
}

.acivity__items .acivity__item {
    @apply text-gray-50 text-lg xl:text-2xl pr-6 xl:pr-10 cursor-pointer hover:text-blaze-500 transition-all duration-300
}

.secondary-dark .title {
    @apply text-blue-50 md:text-2xl xl:text-4xl lg:text-4xl
}

.content-center .btn-primary {
    @apply self-center mt-6
}

.content-center .wrapper {
    @apply max-w-[90%] md:max-w-[80%] xl:max-w-[70%] mx-auto flex flex-col items-center justify-center text-center;
}

.content-center .content {
    @apply self-center
}
.content-center.secondary-dark .content > p {
    @apply text-lg font-montserrat text-blue-100 font-normal max-w-full lg:max-w-[60%] mx-auto
}

.content-center.none .content > p {
    @apply text-lg font-montserrat text-gray-800 font-normal max-w-full lg:max-w-[60%] mx-auto
}

.content-center.none .title {
    @apply text-blaze-500 md:text-2xl xl:text-4xl lg:text-4xl mb-4
}

.banner-cta {
    @apply relative !p-0
}

.banner-cta > .overlay {
    @apply absolute top-0 left-0 right-0 w-full flex flex-1 items-center justify-center;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
}

.banner-cta > .overlay > a {
    @apply flex flex-col flex-1 items-center justify-center
}

.banner-cta > .overlay  .title-container p {
    @apply font-black font-montserrat text-3xl md:text-4xl xl:text-7xl text-white text-center

}

.banner-cta > .overlay  .btn-primary {
    @apply self-center mt-12 text-2xl font-montserrat
}

.primary-dark .icon-group__content > p,.secondary-dark .icon-group__content > p {
    @apply text-white
}

.two-column-image-text .additional {
    @apply flex-none
}


.two-column-image-text .content  p {
    @apply text-base
}
.two-column-image-text .content strong {
    @apply font-montserrat
}
.two-column-image-text .content ul {
    @apply list-disc pl-6 text-gray-500 font-rubik text-sm
}


.banner-cta {
    object-fit: cover;
    min-height: 400px;
    overflow: hidden;
    display: flex;
}
.banner-cta > img {
    max-width: unset;
    min-width: 100%;
    height: auto;
    object-fit: cover

}
.cms_post__content .banner-cta {
    @apply mb-7
}
.cms_post__content .banner-cta img {
    @apply max-w-none lg:max-w-full rounded-lg max-h-[500px] md:max-h-full
}