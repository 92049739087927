.input-field {
    @apply flex flex-col flex-1 gap-[5px] mb-5 font-montserrat
}
.input-field label {
    @apply text-base text-gray-700
}

.input-field [type=text], .input-field [type=email], .input-field [type=tel], .input-field textarea   {
    @apply p-3 rounded-md text-gray-500 border-gray-300 border-[1px] w-full appearance-none leading-tight focus:ring-offset-0 focus:ring-0 focus:outline-blaze-500
}

.input-field textarea {
    @apply min-h-[100px] max-h-[150px]
}

.input-field .react-international-phone-input-container .react-international-phone-input {
    @apply  p-3 rounded-r-md text-gray-500 h-auto border-gray-300  border-[1px] w-full appearance-none leading-tight  focus:ring-offset-0 focus:ring-0 focus:outline-blaze-500 focus:outline focus:outline-[1px];
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
}

.input-field .react-international-phone-country-selector-button {
    @apply p-2.5 h-auto rounded-l-md border-gray-300  border-[1px] border-r-0 appearance-none  focus:ring-offset-0 focus:ring-0 group-focus:outline-blaze-500 group-focus:outline group-focus:outline-[1px]
}

.checkbox-field {
    @apply flex gap-2 flex-col
}

.checkbox-field label {
    @apply text-base text-gray-700
}

.checkbox-field input[type=checkbox] {
    @apply  appearance-none w-4 h-4 border-2 border-blaze-500 rounded-sm bg-white
    mt-1 shrink-0
    checked:bg-blaze-500 checked:border-0 checked:accent-white
    focus-within:hidden
}
.checkbox-group {
    @apply flex flex-row gap-4
}

[type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23262626' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.input-group-row {
    @apply gap-[15px] md:gap-[25px] flex md:flex-row flex-col flex-1
}

.select {
    @apply w-full
}

.select-box {
    @apply flex flex-wrap items-center
    px-3.5
    py-2.5
    w-full
    rounded-md cursor-pointer
    border
    border-solid
    border-gray-300
    bg-white

}

.select-box .selected {
    @apply flex items-center bg-blaze-500 text-white px-2 py-1 m-1 rounded
}

.select-box.focused {
    @apply border-blaze-500
}

.react-select__option--is-selected {
    @apply !bg-blaze-500
}
.react-select__option--is-focused {
    @apply !bg-blaze-100
}