.timeline-numbered a[class*="btn-"]{
    @apply bg-blaze-500 text-white flex items-center hover:bg-blaze-600 rounded-md
}
.timeline-numbered.center {
    @apply bg-gray-50
}
.timeline-numbered.center .container {
    @apply lg:max-w-[50%] max-w-full mx-auto
}

.timeline-numbered .container > .strapline, .timeline-numbered .container > .content     {
    @apply text-center
}
.timeline-numbered .container .steps .column {
    @apply max-w-[600px]
}
.timeline-numbered .container .steps .content     {
    @apply  mb-4 md:mb-12 text-3xl
}

.timeline-numbered .container .steps .content p {
    @apply text-xl
}

.steps  {
    @apply mt-[100px]
}

.steps.left .column {
    @apply ml-[40px]  pl-[60px] md:ml-[100px] md:pl-[100px] relative border-l-2 border-blaze-500 pb-[100px]
}

.steps.left .column:last-of-type{
    @apply border-0
}

body {
    counter-reset: timeline;
}

.timeline-numbered .container .steps .column::before {
    counter-increment: timeline;
    content:counter(timeline) ;
    @apply absolute left-[-30px] top-[-10px] bg-blaze-500 text-white rounded-full text-center flex justify-center items-center w-[60px] h-[60px] font-bold text-2xl
}


.timeline-numbered .container .steps .column .code.content > p {
    @apply text-base mt-2 mb-2
}