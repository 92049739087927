.cms_post__header {
    @apply min-h-[400px] flex
}

.cms_post__header .container {
    @apply  flex flex-col flex-1 items-center justify-center text-center
}

.cms_post__published {
    @apply text-blaze-500 font-bold mb-3 text-lg
}

.cms_post__header_title {
    @apply text-gray-800 text-4xl font-black font-montserrat max-w-full lg:max-w-[70%] mx-auto
}

.cms_post__excerpt {
    @apply text-gray-500 text-lg  font-montserrat max-w-full lg:max-w-[70%] mx-auto mt-8
}
.cms_post__content {
    @apply flex flex-col flex-1 pt-0
}
.cms_post__content >  .container {
    @apply flex flex-col flex-1   max-w-full lg:max-w-[50%] mx-auto
}

.cms_post__content .container figure {
    @apply mb-4 pb-12 border-b-[1px] border-gray-200 min-w-full max-h-[400px]
}
.cms_post__content .container figure img {
    @apply object-cover object-bottom min-w-full
}

.cms_post__content .container .code.content {
    @apply min-w-full
}

.cms_post__content .container .code.content h2,
.cms_post__content .container .code.content h3 {
    @apply leading-tight text-xl
}
.cms_post__content .container .code.content > p {
    @apply text-base
}

.cms_post__content .container .code.content > p strong {
    @apply text-base text-blaze-500
}

.cms_post__content .container .code.content > ul > li {
    @apply text-base
}

.cms_post__content .container .conclusion {
    @apply bg-gray-50 rounded-lg px-8 py-6 mt-6
}

.cms_post_list .container {
    @apply flex flex-col lg:flex-row flex-1 gap-4
}

.cms_post_list_sidebar_title {
    @apply text-blaze-500 font-montserrat font-bold text-lg
}

.cms_post_list_sidebar_list {
    @apply pl-3 flex flex-col gap-2 text-gray-800
}

.cms_post_list_sidebar_list a {
    @apply hover:text-blaze-500
}

.cms_post_list_item {
    @apply flex flex-col gap-4
}
.cms_post_list_item__content {
    @apply flex-1 flex flex-col
}

.cms_post_list_item figure {
    @apply h-[350px] w-full object-center
}

.cms_post_list_item figure img {
    @apply object-cover min-w-full
}

.cms_post_list_item__content_title {
    @apply text-lg font-montserrat font-medium text-gray-800;
    flex:1 0 auto;
}

.cms_post_list_item__published {
    @apply text-sm font-bold text-blaze-500 mb-2 mt-4
}

.cms_post_list_item__content_except {
    @apply text-base text-gray-500 mt-3 line-clamp-3;

}

.mega-menu__column .cms_post_list_item a:hover {
    @apply bg-none bg-transparent
}
.mega-menu__column .cms_post_list_item__published {
    @apply mt-0
}

.cms_post__content .container figure.image {
    max-height: 100%;
}

.cms_post__content .container figure.image img {
    @apply w-full
}

.pagination {
    @apply flex flex-1 justify-between items-center gap-x-1 mt-[6rem] mb-8 border-t-[1px] border-gray-200 pt-6
}

.pagination-button {
    @apply min-h-[38px] min-w-[38px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none dark:text-white dark:hover:bg-white/10
}

.pagination-button {
    @apply text-gray-800
}
.pagination-button.active {
    @apply bg-blue-900 text-white
}

.next,.prev {
    @apply min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:text-blaze-500 focus:outline-none
}


.cms_post__content .two-column-image-text {
    order:initial;
}

.cms_post__content .two-column-image-text .title {
    @apply leading-tight text-xl text-blue-800
}
.cms_post__content .two-column-image-text .content > p {
    @apply text-base
}