/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;

/**
 * Use this directive to control where Tailwind injects the responsive
 * variations of each utility.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind screens;

figure > img {
    @apply object-cover rounded-[15px]
}


@layer base {
    html {
        @apply font-rubik overflow-x-hidden min-h-screen;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        @apply min-h-screen
    }

    main {
        @apply h-full
    }

    p {
        @apply text-gray-600 font-rubik
    }

    section {
        @apply py-[50px]
    }

    figure {
        @apply flex
    }

}

@layer utilities {

    .pb-safe {
        padding-bottom: env(safe-area-inset-bottom);
    }

    .text-oblique {
        font-style: oblique;
    }

    .row {
        @apply flex flex-col md:flex-row gap-[20px] flex-1;
    }

    .column {
        @apply flex flex-col gap-[20px] flex-1;
    }

    .title-container {
        @apply flex flex-col gap-2
    }


    .title {
        @apply text-blaze-500 font-bold font-montserrat text-2xl
    }

    .strapline {
        @apply text-blue-950 font-medium font-lora text-lg capitalize  text-oblique
    }


    .flex-0 {
        flex:0;
    }

    .rounded-tl-0 {
        -moz-border-radius-topleft: 0;
        border-top-left-radius: 0;
    }

    .rounded-l-0 {
        -moz-border-radius-topleft: 0;
        border-top-left-radius: 0;
        -moz-border-radius-topright: 0;
        border-top-right-radius: 0;
    }

}

.row.reverse-mob {
    @apply flex flex-col-reverse md:flex-row gap-[20px] flex-1;
}

#shadow-host-companion {
    padding:0;
}

.style-gray {
    @apply bg-gray-50
}

.style-white {
    @apply bg-white
}

.style-primary{
    @apply bg-blaze-50
}

.style-secondary {
    @apply bg-blue-50
}

.image-start {
    @apply flex
}

.image-end > .row {
    @apply flex-row-reverse
}

.image-start > .row > .column:last-child {
    @apply lg:content-end flex-wrap
}

.image-end> .row > .column:last-child {
    @apply  flex-wrap
}

.animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.5);
        box-shadow: 0 0 0 0 rgba(228, 81, 18, 1);
    }

    40% {
        transform: scale(0.7);
        box-shadow: 0 0 0 60px rgba(228, 81, 18, 0.2);
    }

    70% {
        transform: scale(0.7);
        box-shadow: 0 0 0 60px rgba(228, 81, 18, 0);
    }

    100% {
        transform: scale(0.5);
    }
}


.content {
    @apply prose-p:text-sm prose-p:text-gray-500 prose-a:text-blaze-500 prose-a:hover:text-blaze-600 prose-p:mb-3
}

#header.nav-bar--hidden {
    transform: translateY(-100%);
}


.page.styles > .wrapper {
    @apply container flex flex-col gap-[25px] mb-6 mt-4

}

.column.hide-mob {
    @apply hidden md:flex
}

.breadcrumbs {
    @apply flex text-sm
}

.breadcrumbs.dark {
    @apply text-gray-500
}

.breadcrumbs.light {
    @apply text-white
}

.breadcrumbs > .list {
    @apply inline-flex items-center space-x-1 md:space-x-3
}

.breadcrumbs .item {
    @apply inline-flex items-center text-gray-500 hover:text-gray-700
}

.breadcrumbs.light .item {
    @apply inline-flex items-center text-white hover:text-blue-100
}
.breadcrumbs .last {
    @apply text-gray-700 font-medium
}
.breadcrumbs.light .last {
    @apply text-blue-200 font-medium
}

.secondary-dark .strapline {
    @apply text-blaze-300
}

.secondary-dark .title {
    @apply text-blue-100
}
.cms-legal .column > div p {
    @apply text-base font-montserrat text-gray-800 mb-2
}

.cms-legal .column > div a {
    @apply text-blaze-500
}

.cms-legal .column > div ul {
    @apply list-disc pl-6 flex flex-col gap-2 text-base font-montserrat text-gray-800
}
.cms-legal .column > div {
    @apply flex flex-col gap-[20px] flex-1;
}

.cms-legal h3, .cms-legal h2 {
    @apply text-blaze-500 font-bold font-montserrat text-xl
}

.cms-legal h4  {
    @apply text-blue-800 font-rubik text-lg font-bold
}

.cms-legal strong {
    @apply text-blue-800 font-rubik font-bold
}

.cms-legal #toc {
    @apply gap-0
}

.cms-legal #toc a {
    @apply text-gray-500 text-sm hover:text-gray-800 font-montserrat
}

.cms-legal #toc h3 {
    @apply mb-3
}

.cms-legal #toc ul {
    @apply list-none pl-0
}

/* Table base styles */
table {
    @apply min-w-full border-collapse border border-gray-200;
}

th,
td {
    @apply px-4 py-2 text-left border-b border-gray-200 min-w-[150px];
}

td:first-child,
th:first-child {
    @apply w-48; /* Set the desired fixed width */
}

th {
    @apply bg-gray-100 text-gray-700 font-bold;
}

tr:hover {
    @apply bg-gray-50;
}

.highlight {
    @apply bg-blue-100 text-blue-700;
}

/* Responsive styles */
@media (min-width: 640px) {
    table {
        @apply table-auto;
    }
}



.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transition: opacity 0.3s, transform 0.3s;

    @apply bg-blaze-500;
}

.scroll-to-top:hover {
    transform: translateY(-5px);
}

.scroll-to-top svg {
    font-size: 24px;
}