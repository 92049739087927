.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom:0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background: white;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeInModal 0.3s forwards;
}

@keyframes fadeInModal {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    @apply text-gray-500;
}

.modal-content {
    padding: 1rem 0;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}